import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import SelectableList from "./SelectableList";
import { config } from "./Config";

function ArticleList(props) {
    const onSelectArticle = props.onSelectArticle;
    const mask = props.mask;
    const truncate = props.truncate;
    const modified = props.modified;
    const count = 1000; // TODO: make this a prop and selectable

    const empty_data = [{
        article_id: "1",
        article_type: "review",
        editor: null,
        author: null,
        title: "-",
        keywords: null,
        abstract: "-",
        image_alt: "",
        image_url: "",
        image_caption: "",
        body: "-",
        added: "2023-07-24 23:55:18",
        modified: "2023-07-24 23:55:18",
        published: "",
        error: ""
    }];

    const [data, setData] = useState(empty_data);

    const url = config.php_url + "/latest.php?count=" + count + "&compact=0&all=true";
 
    // This could be done more efficiently
    const fetchArticleData = () => {
        fetch(url, {method: 'GET'})
            .then(response => {
             console.log(response)
            return response.json()
        })
        .then(data => {
            // TODO: check for error from server
            console.log(data);
            setData(data);
        })
        .catch((error) => {
            console.error(error);
            setData(empty_data);
        })
    }

    useEffect(() => {
        fetchArticleData();
    }, [count,modified]);

    const all_headers = ["article_id", "article_type", "editor", "author", "keywords", "title", "abstract", "image_alt", "image_url", "image_caption", "body", "added", "modified", "published"];
    var headers = [];
    var rows = [];

    for (let j = 0; j < all_headers.length; j++) {
        if ((mask & (1 << j)) != 0) {
            headers.push(all_headers[j]);
        }
    }

    for (let i = 0; i < data.length; i++) {
        var row = [];
        for (let j = 0; j < headers.length; j++) {
            var text = data[i][headers[j]];
            if (text == null) {
                text = "(null)";
            }
            // Get substring of length truncate from text and add "..."
            if (text.length > truncate) {
                text = text.substring(0, truncate) + "...";
            }
            row.push(text);
        }
        rows.push(row);
    }

    function handleSelectRow(row) {
        console.log("handleSelectRow: " + row);
        if (row < 0) {
            onSelectArticle({});
        } else {
            onSelectArticle(data[row]);
        }
    }

    return (
        <SelectableList headers={headers} rows={rows} onSelectRow={(row) => {handleSelectRow(row)}} />
    );
}

export default ArticleList;
