const prod = {
    pic_url: "https://ai-and-science.com/pics",
    php_url: "/php",
    preview_url: "https://ai-and-science.com/preview"    
};

const dev = {
    pic_url: "http://localhost/aas-dev/pics",
    php_url: "/aas-dev/php",
    preview_url: "http://localhost:3000/preview"    
};

export const config = process.env.NODE_ENV === "development" ? dev : prod;
