
import React, { useEffect, useState } from "react";
import { useNavigate, BrowserRouter, Routes, Route, Link, useParams } from "react-router-dom";

// import './App.css';
import './w3.css';
import './my.css';
import ArticleList from "./ArticleList";
import Layout from "./Layout";
import { config } from "./Config";

//
// The main page showing the article list
//
function HomeView(props) {
  const navigate = useNavigate();

  const [article, setArticle] = useState({});
  const [modified, setModified] = useState(0);

  // Callback for article list, called when an article is selected
  function onSelectArticle(art) {
    console.log("onSelectArticle: " + art);
    setArticle(art);
  }

  // Callback for Edit button
  function onEdit() {
    if (!article || !article.article_id) {
      alert("No article selected!");
    }
    else {
      navigate('/edit/' + article.article_id);
    }
  }

  // Callback for Publish button
  function onPublish() {
    if (!article || !article.article_id) {
      alert("No article selected!");
    }
    else {
      navigate('/publish/' + article.article_id);
    }
  }

  // Callback for Delete button
  function onDelete() {
    if (!article || !article.article_id) {
      alert("No article selected!");
    }
    else {
      navigate('/delete/' + article.article_id);
    }
  }

  return (
    <div>
      <div className="w3-container">
      <b>Env: </b><code>{process.env.NODE_ENV}</code><br/>
      <b>PHP: </b> <code>{config.php_url}</code><br/>
      <b>Site: </b> <code>{config.preview_url}</code><br/>
      <b>Images: </b> <code>{config.pic_url}</code><br/>

      <h2>Add a new article to the database:</h2>

      <nav classname="w3-bar">
      <Link to="/new" className="w3-bar-item w3-button w3-yellow">New article...</Link>
      </nav>
      
      <h2>Actions on the selected article:</h2>

      <p>
      <a href={config.preview_url + '/' + article.article_id}
            target="_blank" className="w3-bar-item w3-button w3-blue">Preview in new tab -&gt; </a>
      </p>

      <nav classname="w3-bar">
          <div className="w3-bar-item w3-button w3-yellow" onClick={onEdit}>Edit article...</div>
          <div className="w3-bar-item w3-button w3-orange w3-margin-left"  onClick={onPublish}>Publish article...</div>
          <div className="w3-bar-item w3-button w3-red w3-margin-left" onClick={onDelete}>Delete article...</div>
      </nav>
      </div>

      <div className="w3-card-2 w3-margin w3-white">
        <ArticleList mask="0b11110001101001" truncate="20" modified={modified} onSelectArticle={onSelectArticle} />
      </div>

      <div className="w3-container">
      <h2>Article JSON:</h2>
      </div>

      <div className="w3-card-2 w3-margin w3-white">
          <div className="w3-container">
            <pre>
              {JSON.stringify(article, null, 2)}
            </pre>
          </div>
      </div>
    </div>
  );
}

//
// Page for adding a new article
//
function NewView(props) {
  const navigate = useNavigate();

  const empty_data = {
    article_type: "review",
    author: "",
    title: "",
    abstract: "",
    image_alt: "",
    image_url: "mockup-00.jpg",
    image_caption: "",
    body: ""
  };

  const [data, setData] = useState(empty_data)

  function setDataField(field, value) {
    console.log("setDataField: " + field + " = " + value);
    setData({...data, [field]: value});
  }

  function onCancel() {
    navigate('/');
  }

  function onAdd() {
    // Set credentials in the header
    var headers = new Headers();
    var username = sessionStorage.getItem("username");
    var password = sessionStorage.getItem("password");

    headers.set('Authorization', 'Basic ' + btoa(username + ":" + password));

    console.log("onAdd: " + JSON.stringify(data));
    fetch(config.php_url + '/store.php', {
      method: 'POST',
      headers: headers,
      body: JSON.stringify(data)
    })
    .then(response => {
      console.log("onAdd response: " + response);
      return response.json();
    })
    .then(response => {
      console.log("onAdd response: " + response);
      if ('error' in response) {
        console.error('Error:', response.error);
        alert('Error: ' + response.error);
      } else {
        navigate('/');
      }
    })
    .catch((error) => {
      console.error('Error:', error);
      alert('Error: ' + error);
    });
  }
    

  return (
    <div>
      <div>
        <div className="w3-container">
          <h2>Add New Article</h2>
          <p>To add a new article to the database, please enter your (author's) name and a draft
          title so that you can recognize the article later in the
          list (you need to find it again at the main page). You can edit them later.</p>

          <nav classname="w3-bar">
            <div className="w3-bar-item w3-button w3-green w3-hover-text-grey" onClick={onCancel}>Cancel</div>
            <div className="w3-bar-item w3-button w3-yellow w3-hover-text-grey w3-margin-left" onClick={onAdd}>Add</div>
          </nav>
        </div>

        <div className="w3-card-2 w3-margin w3-white">
          <div className="w3-container">
            <p>
              <b>Author:</b>
              <input className="full_width" type="text" placeholder="Author..." onChange={e => setDataField("author", e.target.value)} />
            </p>
            <p>
              <b>Title:</b>
              <input type="text" placeholder="Title..." onChange={e => setDataField("title", e.target.value)} />
              </p>
          </div>

        </div>
      </div>

      <div className="w3-container">
      <h2>JSON to be sent:</h2>
      </div>

      <div className="w3-card-2 w3-margin w3-white">
        <div className="w3-container">
          <pre>
            {JSON.stringify(data, null, 2)}
          </pre>
        </div>
      </div>
    </div>
    );
}

//
// Page for editing an article
//
function EditView(props) {
  const navigate = useNavigate();

  // Get article id as url parameter
  let { id } = useParams();

  const empty_data = {
    article_id: "(undefined)",
    article_type: "review",
    editor: "",
    author: "",
    title: "",
    keywords: "",
    abstract: "",
    image_alt: "",
    image_url: "",
    image_caption: "",
    body: "",
  };

  const [data, setData] = useState(empty_data);

  // Fetch article data from server
  const fetchArticleData = () => {
    fetch(config.php_url + "/fetch.php?id=" + id, {method: 'GET'})
      .then(response => {
        console.log(response)
        return response.json()
      })
      .then(init_data => {
        console.log(init_data);
        if ('error' in init_data) {
          console.error('Error:', init_data.error);
          alert('Error: ' + init_data.error);
        } else {
          var new_data = {};
          for (var field in empty_data) {
            new_data[field] = init_data[field];
          }
          setData(new_data);
        }
      })
      .catch((error) => {
        console.error(error);
        setData(empty_data);
        alert('Error: ' + error);
      })
  }

  useEffect(() => {
    fetchArticleData();
  }, [id]);

  // Callback for return button
  function onReturn() {
    navigate('/');
  }

  // Callback for store button, store the article to the database
  function onStore() {
    // Set credentials in the header
    let headers = new Headers();
    var username = sessionStorage.getItem("username");
    var password = sessionStorage.getItem("password");

    headers.set('Authorization', 'Basic ' + btoa(username + ":" + password));

    console.log("onStore: " + JSON.stringify(data));
    fetch(config.php_url + '/store.php', {
      method: 'POST',
      headers: headers,
      body: JSON.stringify(data)
    })
    .then(response => {
      console.log("onStore response: " + response);
      return response.json();
    })
    .then(response => {
      console.log("onStore response: " + response);
      if ('error' in response) {
        console.error('Error:', response.error);
        alert('Error: ' + response.error);
      } else {
        alert('Article stored!');
      }
    })
    .catch((error) => {
      console.error('Error:', error);
      alert('Error: ' + error);
    });
  }
    
  // Callback for changing a field in the data
  function setDataField(field, value) {
    console.log("setDataField: " + field + " = " + value);
    setData({...data, [field]: value});
  }

  return (    
    <div>
      <div className="w3-container">
        <h2>Edit Article {data.article_id}</h2>
        <p>Your are editing an existing article in the database. Please remember to save your changes
          by clicking "Store". The article will be updated in the database. Pressing "Return" will not
          store your changes.</p>

          <p>
        <a href={config.preview_url + '/' + data.article_id}
              target="_blank" className="w3-bar-item w3-button w3-blue">Preview in new tab -&gt; </a>
        </p>

        <nav classname="w3-bar">
        <div className="w3-bar-item w3-button w3-green w3-hover-text-grey" onClick={onReturn}>Return</div>
        <div className="w3-bar-item w3-button w3-yellow w3-hover-text-grey w3-margin-left" onClick={onStore}>Store</div>
        </nav>
      </div>

      <div className="w3-card-2 w3-margin w3-white">
        <div className="w3-container">
          <p>
            <b>Author:</b>
            <input type="text" placeholder="Author..." value={data.author || ""} onChange={e => setDataField("author", e.target.value)} />
            </p><p>
            <b>Article type: </b>
            <select value={data.article_type || ""} onChange={e => setDataField("article_type", e.target.value)} >
            <option value="original">original</option>
            <option value="review">review</option>
            <option value="summary">summary</option>
            <option value="sponsored">sponsored</option>
            </select>
            </p><p>
            <b>Title:</b>
            <input type="text" placeholder="Title..." value={data.title || ""} onChange={e => setDataField("title", e.target.value)} />
            </p><p>
            <b>Keywords:</b>
            <input type="text" placeholder="Keywords..." value={data.keywords || ""} onChange={e => setDataField("keywords", e.target.value)} />
            </p><p>
            <b>Abstract:</b>
            <textarea type="text" placeholder="Abstract..." value={data.abstract || ""} rows="3" onChange={e => setDataField("abstract", e.target.value)} />
            </p><p>
            <b>Image alt:</b>
            <input type="text" placeholder="Image alt..." value={data.image_alt || ""} onChange={e => setDataField("image_alt", e.target.value)} />
            </p><p>
            <b>Image file: </b>
            <input type="text" placeholder="Image URL..." value={data.image_url || ""} onChange={e => setDataField("image_url", e.target.value)} style={{width: "25%"}} /> <a href={config.pic_url + "/" + data.image_url}
            target="_blank" className="w3-bar-item w3-button w3-blue w3-margin">Show image -&gt;</a>
            </p><p>

            <b>Image caption:</b>
            <input type="text" placeholder="Image caption..." value={data.image_caption || ""} onChange={e => setDataField("image_caption", e.target.value)} />
            </p><p>
            <b>Body:</b>
            <textarea type="text" placeholder="Body..." value={data.body || ""} rows="20" onChange={e => setDataField("body", e.target.value)} />
            </p>
        </div>
      </div>

      <div className="w3-container">
      <h2>Article JSON:</h2>
      </div>

      <div className="w3-card-2 w3-margin w3-white">
          <div className="w3-container">
            <pre>
              {JSON.stringify(data, null, 2)}
            </pre>
          </div>
      </div>
    </div>);
}


//
// Page for publishing an article
//
function PublishView(props) {
  const navigate = useNavigate();

  // Get article id as url parameter
  let { id } = useParams();

  const empty_data = {
    article_id: "(undefined)",
    article_type: "review",
    editor: "",
    author: "",
    title: "",
    keywords: "",
    abstract: "",
    image_alt: "",
    image_url: "",
    image_caption: "",
    body: "",
    stars: ""
  };

  const [data, setData] = useState(empty_data);

  // Fetch article data from server
  const fetchArticleData = () => {
    fetch(config.php_url + "/fetch.php?id=" + id, {method: 'GET'})
      .then(response => {
        console.log(response)
        return response.json()
      })
      .then(init_data => {
        console.log(init_data);
        if ('error' in init_data) {
          console.error('Error:', init_data.error);
          alert('Error: ' + init_data.error);
        } else {
          var new_data = {};
          for (var field in empty_data) {
            new_data[field] = init_data[field];
          }
          setData(new_data);
        }
      })
      .catch((error) => {
        console.error(error);
        setData(empty_data);
        alert('Error: ' + error);
      })
  }

  useEffect(() => {
    fetchArticleData();
  }, [id]);

  // Callback for return button
  function onReturn() {
    navigate('/');
  }

  // Callback for store button, store the article to the database
  function onStore() {
    // Set credentials in the header
    let headers = new Headers();
    var username = sessionStorage.getItem("username");
    var password = sessionStorage.getItem("password");

    headers.set('Authorization', 'Basic ' + btoa(username + ":" + password));

    console.log("onStore: " + JSON.stringify(data));
    fetch(config.php_url + '/store.php', {
      method: 'POST',
      headers: headers,
      body: JSON.stringify(data)
    })
    .then(response => {
      console.log("onStore response: " + response);
      return response.json();
    })
    .then(response => {
      console.log("onStore response: " + response);
      if ('error' in response) {
        console.error('Error:', response.error);
        alert('Error: ' + response.error);
      } else {
        alert('Article stored!');
      }
    })
    .catch((error) => {
      console.error('Error:', error);
      alert('Error: ' + error);
    });
  }
    
  // Callback for changing a field in the data
  function setDataField(field, value) {
    console.log("setDataField: " + field + " = " + value);
    setData({...data, [field]: value});
  }

  return (    
    <div>
      <div className="w3-container">
        <h2>Publish Article {data.article_id}</h2>
        <p>Your are editing an existing article in the database. Please remember to save your changes
          by clicking "Store". The article will be updated in the database. Pressing "Return" will not
          store your changes.</p>

          <p>
        <a href={config.preview_url + '/' + data.article_id}
              target="_blank" className="w3-bar-item w3-button w3-blue">Preview in new tab -&gt; </a>
        </p>

        <nav classname="w3-bar">
        <div className="w3-bar-item w3-button w3-green w3-hover-text-grey" onClick={onReturn}>Return</div>
        <div className="w3-bar-item w3-button w3-orange w3-hover-text-grey w3-margin-left" onClick={onStore}>Store</div>
        </nav>
      </div>

      <div className="w3-card-2 w3-margin w3-white">
        <div className="w3-container">
          <p>
            <b>Editor:</b>
            <input type="text" placeholder="Editor..." value={data.editor || ""} onChange={e => setDataField("editor", e.target.value)} />
            </p><p>
            <b>Stars:</b>
            <input type="text" placeholder="Stars..." value={data.stars} onChange={e => setDataField("stars", e.target.value)} />
            </p><p>
            <b>Published: </b>
            <input type="datetime-local" onChange={e => setDataField("published", new Date(e.target.value).toISOString().slice(0, 19).replace('T', ' '))} style={{width: "auto"}} />
            <input type="button" value="Clear date" onClick={e => setDataField("published", null)} style={{width: "auto"}} />
            </p>
        </div>
      </div>

      <div className="w3-container">
      <h2>Article JSON:</h2>
      </div>

      <div className="w3-card-2 w3-margin w3-white">
          <div className="w3-container">
            <pre>
              {JSON.stringify(data, null, 2)}
            </pre>
          </div>
      </div>
    </div>);
}


//
// Page for deleting an article
//
function DeleteView(props) {
  const navigate = useNavigate();

  //
  // Get article id as url parameter
  //
  let { id } = useParams();

  const empty_data = {
    article_id: "(undefined)",
    article_type: "review",
    editor: "",
    author: "",
    title: "",
    keywords: "",
    abstract: "",
    image_alt: "",
    image_url: "",
    image_caption: "",
    body: "",
  };

  const [data, setData] = useState(empty_data);

  // Fetch article data from server
  const fetchArticleData = () => {
    fetch(config.php_url + "/fetch.php?id=" + id, {method: 'GET'})
      .then(response => {
        console.log(response)
        return response.json()
      })
      .then(init_data => {
        console.log(init_data);
        if ('error' in init_data) {
          console.error('Error:', init_data.error);
          alert('Error: ' + init_data.error);
        } else {
          setData(init_data);
        }
      })
      .catch((error) => {
        console.error(error);
        setData(empty_data);
        alert('Error: ' + error);
      })
  }

  useEffect(() => {
    fetchArticleData();
  }, [id]);

  // Callback for return button
  function onReturn() {
    navigate('/');
  }

  // Callback for delete button, delete the article from the database
  function onDelete() {
    // Set credentials in the header
    var headers = new Headers();
    var username = sessionStorage.getItem("username");
    var password = sessionStorage.getItem("password");

    headers.set('Authorization', 'Basic ' + btoa(username + ":" + password));

    console.log("onDelete: " + JSON.stringify(data));
    fetch(config.php_url + '/delete.php?id=' + data.article_id, {
      method: 'GET',
      headers: headers})
    .then(response => {
      console.log("onDelete response: " + response);
      return response.json();
    })
    .then(response => {
      console.log("onDelete response: " + response);
      if ('error' in response) {
        console.error('Error:', response.error);
        alert('Error: ' + response.error);
      } else {
        navigate('/');
      }
    })
    .catch((error) => {
      console.error('Error:', error);
      alert('Error: ' + error);
    });
  }

  // Callback for hard delete button, hard delete the article from the database
  function onHardDelete() {
    // Set credentials in the header
    var headers = new Headers();
    var username = sessionStorage.getItem("username");
    var password = sessionStorage.getItem("password");

    headers.set('Authorization', 'Basic ' + btoa(username + ":" + password));

    console.log("onHardDelete: " + JSON.stringify(data));
    fetch(config.php_url + '/delete.php?id=' + data.article_id + "&hard=true", {
      method: 'GET',
      headers: headers})
    .then(response => {
      console.log("onHardDelete response: " + response);
      return response.json();
    })
    .then(response => {
      console.log("onHardDelete response: " + response);
      if ('error' in response) {
        console.error('Error:', response.error);
        alert('Error: ' + response.error);
      } else {
        navigate('/');
      }
    })
    .catch((error) => {
      console.error('Error:', error);
      alert('Error: ' + error);
    });
  }
  
  return (    
    <div>
      <div className="w3-container">
        <h2>Delete Article {data.article_id}</h2>
        <p>Your are about to delete an existing article in the database. To soft delete the article,
          press "Delete" which might fail if the article has existing statistics (i.e., someone has read it).
          To hard delete the article, press "Hard Delete" which will delete the article regardless of tis.
          Pressing "Return" will not delete the article.</p>

          <p>
        <a href={config.preview_url + '/' + data.article_id}
              target="_blank" className="w3-bar-item w3-button w3-blue">Preview in new tab -&gt; </a>
        </p>

        <nav classname="w3-bar">
        <div className="w3-bar-item w3-button w3-green w3-hover-text-grey" onClick={onReturn}>Return</div>
        <div className="w3-bar-item w3-button w3-orange w3-hover-text-grey w3-margin-left" onClick={onDelete}>Delete</div>
        <div className="w3-bar-item w3-button w3-red w3-hover-text-grey w3-margin-left" onClick={onHardDelete}>Hard Delete</div>
        </nav>
      </div>

      <div className="w3-card-2 w3-margin w3-white">
          <div className="w3-container">
          <p>
            <b>Article ID:</b> {data.article_id}<br/>
            <b>Author:</b> {data.author}<br/>
            <b>Title:</b> {data.title}<br/>
            <b>Abstract:</b> {data.abstract}<br/>
            <b>Added:</b> {data.added}<br/>
            <b>Modified:</b> {data.modified}<br/>
            <b>Published:</b> {data.published}<br/>
          </p>
        </div>
      </div>

      <div className="w3-container">
      <h2>Article JSON:</h2>
      </div>

      <div className="w3-card-2 w3-margin w3-white">
          <div className="w3-container">
            <pre>
              {JSON.stringify(data, null, 2)}
            </pre>
          </div>
      </div>
    </div>);
}

//
// Page for showing a missing page
//
function MissingView(props) {
  return (
      <div className="w3-card-2 w3-margin w3-white">
          <div className="w3-container">
      <h2>Oops, page not found</h2>
    </div></div>);
}

//
// Main app
//
function App() {

  const color = "w3-light-gray w3-text-black"
  // const color = "w3-text-light-gray w3-dark-gray" // dark mode

  const [loggedIn, setLoggedIn] = useState(false);

  function handleLogin(event) {

    // Prevent page reload
    event.preventDefault();

    // Get username and password from the form and set as credentials
    var { username, password } = document.forms[0];

    // Set credentials in the header
    let headers = new Headers();
    headers.set('Authorization', 'Basic ' + btoa(username.value + ":" + password.value));

    fetch(config.php_url + '/login.php', {method: "GET", headers: headers})
    .then(response => {
      console.log("login response: " + response);
      return response.json();
    })
    .then(response => {
      if ('error' in response) {
        console.error('Error:', response.error);
        alert('Error: ' + response.error);
      }
      else if ('login' in response) {
        if (response.login === "OK") {
          sessionStorage.setItem("username", username.value);
          sessionStorage.setItem("password", password.value);
          setLoggedIn(true);
        }
        else {
          alert('Wrong username or password!');
        }
      }
    })
    .catch((error) => {
      console.error('Error:', error);
      alert('Error: ' + error);
    });
  }

  if (!loggedIn) {
    // Create a form for asking the username and password. When user presses login button, call function handleLogin()
    return (
      <div className={color}>
      <div className="w3-content" style={{maxWidth: "30em"}}>
        <div className="w3-card-2 w3-margin w3-white">
            <div className="w3-container">
        <h2>Login</h2>
        <form onSubmit={handleLogin}>
          <p>
            <label>Username:</label>
            <input type="text" name="username" placeholder="Username..." required />
          </p>
          <p>
            <label>Password:</label>
            <input type="password" name="password" placeholder="Password..." required />
          </p>
          <p>
            <input type="submit" value="Login" />
          </p>
        </form>
      </div></div></div></div>);
  }
  else {
    return (
      <BrowserRouter>
        <Routes>
          <Route path="/" element={<Layout />}>
            <Route index element={<HomeView/>} />
            <Route path="new" element={<NewView />} />
            <Route path="edit">
              <Route path=":id" element={<EditView/>} />
            </Route>
            <Route path="publish">
              <Route path=":id" element={<PublishView/>} />
            </Route>
            <Route path="delete">
              <Route path=":id" element={<DeleteView/>} />
            </Route>
            <Route path="*" element={<MissingView/>} />
          </Route>
        </Routes>
      </BrowserRouter>
    );
  }
}

export default App;