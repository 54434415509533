//
// A scrollable list of selectable items where we can select one.
//

import React, { useEffect, useState } from "react";

function SelectableList(props) {
    const onSelectRow = props.onSelectRow;
    const headers = props.headers;
    const rows = props.rows;

    const [selected, setSelected] = useState(-1);

    const sel_color = "w3-light-green";
    const unsel_color = "w3-white w3-hover-light-gray";
    const row_indices = [...Array(rows.length).keys()];

    return (
        <table className="fixed_header"><thead><tr>
        {headers.map(text => <th>{text}</th>)}
        </tr></thead><tbody>
        {row_indices.map(i => <tr onClick={() => {if (i == selected) {setSelected(-1); ; onSelectRow(-1)} else {setSelected(i); onSelectRow(i)}}}
            className={i == selected ? sel_color : unsel_color}>{rows[i].map(text => <td>{text}</td>)}</tr>)}
        </tbody></table>
    );
}

export default SelectableList;